import { useMemo } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
// cmp
import Na from "../na";
// services
import type { GridColDef } from "@mui/x-data-grid";

const UPDATE_STATUS = {
	update: "UPDATED",
	noupdate: "NO UPDATE AVAILABLE",
	error: "ERROR",
} as const;

interface FirmwareInfo {
	name: string;
	version: string;
	status: string;
}

type Props = Readonly<{
	updatedFirmwareInfo?: Array<FirmwareInfo>;
}>;

const DEFAULT_UPDATED_FIRMWARE_INFO = [] as const satisfies Array<FirmwareInfo>;

const UpdatedFirmwareInfo = ({ updatedFirmwareInfo = DEFAULT_UPDATED_FIRMWARE_INFO }: Props) => {
	const { t } = useTranslation();

	const columns = useMemo(() => ([
		{
			field: "name",
			headerName: t("gateway.plugin"),
			flex: 1,
		},
		{
			field: "status",
			headerName: t("gateway.updatedStatus"),
			flex: 1,
			renderCell: (params) => (UPDATE_STATUS[params.value] ?? <Na />),
		},
		{
			field: "version",
			headerName: t("gateway.currentVersion"),
			flex: 1,
			renderCell: (params) => (params.value ?? <Na />),
		},
	] as const satisfies Array<GridColDef<FirmwareInfo>>), [t]);

	return (
		<DataGrid
			columns={columns}
			rows={updatedFirmwareInfo}
			getRowId={(row) => (row.name)}
		/>
	);
};

export default UpdatedFirmwareInfo;
