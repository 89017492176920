// cmp
import CenterCircularProgress from "../cmp/CenterCircularProgress";
import GatewayTabs from "../cmp/gateway/GatewayTabs";
// hooks
import useSelectedGateway from "../hooks/useSelectedGateway";
// contexts
import { useUserData } from "../context/UserDataContext";

const GatewaysPage = () => {
	const { ready } = useUserData();
	const { loading, gateway } = useSelectedGateway();

	if (!ready || loading) {
		return <CenterCircularProgress />;
	}

	if (!gateway?.id) {
		return null;
	}

	return (
		<GatewayTabs
			gatewayId={gateway.id}
			gatewayLabel={gateway.name ?? gateway.id}
		/>
	);
};

export default GatewaysPage;
