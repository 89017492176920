import { useState, useEffect, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
	Paper,
	Button,
	IconButton,
	TextField,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from "@mui/material";
import Svg from "../svg";
import { GridActionsCellItem, DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";
// cmps
import Na from "../na";
// contexts
import { useUserData } from "../../context/UserDataContext";
// services
import Gupport from "../../services/gupport";
import { sortAlphabetically } from "../../services/l10n";
// types
import type { GridRowId, GridColDef } from "@mui/x-data-grid";
import type { CmdDelGatewayHttp, CmdGetGatewayHttp, CmdSetGatewayHttp } from "../../types/gupport";
import type { GatewayId, GatewayHTTP as GatewayHTTPT } from "../../types/gateway";

const CMD_REGEX = /^eval \$\(echo ([A-Za-z0-9+/=]+)\|base64 -d\)$/;

type Props = Readonly<{
	gatewayId: GatewayId;
}>;

const GatewayHTTP = ({ gatewayId }: Props) => {
	const theme = useTheme();
	const { t } = useTranslation();

	const { ready } = useUserData();

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);
	const [https, setHttps] = useState<Array<GatewayHTTPT>>([]);
	const [openDialog, setOpenDialog] = useState(false);
	const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
	const [command, setCommand] = useState("");
	const [selectedHttp, setSelectedHttp] = useState<GatewayHTTPT | undefined>(undefined);
	const [action, setAction] = useState("");
	const isDeleteCmdAvail = Boolean(Gupport.delGatewayHttp);

	const getGatewayHttp = (gatewayId: GatewayId) => {
		const cmd = {
			action: "getGatewayHttp",
			gatewayId: gatewayId,
		} as const satisfies CmdGetGatewayHttp;
		Gupport.send(cmd, (_error, msg) => {
			if (!_error && msg?.payload.status === "ok") {
				const https = msg.payload.data;
				https.sort((a, b) => (sortAlphabetically(a.request_ts, b.request_ts)));
				setError(null);
				setHttps(https);
			} else {
				setError(_error?.message ?? msg.payload.data);
				setHttps([]);
			}
			setLoading(false);
		});
	};

	useEffect(() => {
		if (ready) {
			getGatewayHttp(gatewayId);
		} else {
			Gupport.once("ready", () => {
				getGatewayHttp(gatewayId);
			});
		}
	}, [gatewayId, ready]);

	const handleClose = () => {
		setOpenDialog(false);
		setOpenRemoveDialog(false);
	};

	const removeHttp = () => {
		if (selectedHttp) {
			const cmd = {
				action: "delGatewayHttp",
				gatewayId: gatewayId,
				httpId: selectedHttp.httpId,
			} as const satisfies CmdDelGatewayHttp;
			Gupport.send(cmd, (_error, msg) => {
				if (!_error && msg?.payload.status === "ok") {
					handleClose();
					getGatewayHttp(gatewayId);
				} else {
					setAction(t("gateway.removeHttpError"));
					setOpenDialog(true);
					setOpenRemoveDialog(false);
				}
			});
		}
	};

	const onChange = (event) => {
		setCommand(event.target.value);
	};

	const sendHttpCommands = (isBash) => {
		if (command.trim()) {
			const encodedCmd = globalThis.btoa(command.trim());
			const cmd = {
				action: "setGatewayHttp",
				gatewayId: gatewayId,
				cmd: isBash ? `eval $(echo ${encodedCmd}|base64 -d)` : `X=$(echo ${encodedCmd}|base64 -d) && /usr/roc/core3mips/core3/bin/core3 eval $X`,
			} as const satisfies CmdSetGatewayHttp;
			Gupport.send(cmd, (_error, msg) => {
				if (!_error && msg?.payload.status === "ok") {
					setCommand("");
					getGatewayHttp(gatewayId);
				} else {
					setAction(t("gateway.addHttpError"));
					setOpenDialog(true);
				}
			});
		}
	};

	const showRemoveDialog = useCallback((id: GridRowId) => (
		() => {
			setOpenRemoveDialog(true);
			setSelectedHttp(https.find((http) => (http.httpId === id)));
		}
	), [https]);

	const columns = useMemo(() => ([
		{
			field: "cmd",
			headerName: t("gateway.hCommand"),
			flex: 1,
			valueGetter: (value) => {
				const result = CMD_REGEX.exec(value);
				if (result !== null && result.length > 1) {
					return globalThis.atob(result[1]);
				}
				return value;
			},
		},
		{
			field: "status",
			headerName: t("gateway.hStatus"),
			flex: 1,
		},
		{
			field: "action_ts",
			headerName: t("gateway.executed"),
			flex: 1,
			renderCell: (params) => (
				params.value ? <time dateTime={new Date(params.value).toISOString()}>{new Date(params.value).toLocaleString()}</time> : <Na />
			),
		},
		{
			field: "request_ts",
			headerName: t("gateway.requested"),
			flex: 1,
			renderCell: (params) => (<time dateTime={new Date(params.value).toISOString()}>{new Date(params.value).toLocaleString()}</time>),
		},
		{
			field: "username",
			headerName: t("gateway.hUsername"),
			flex: 3,
		},
		{
			field: "actions",
			headerName: "Action",
			type: "actions",
			flex: 1,
			getActions: (params) => (
				(params.row.status === "pending" && isDeleteCmdAvail) ? [
					<GridActionsCellItem
						key={params.id}
						label="Remove"
						icon={<Svg src="actions/removeCircle.svg" color={theme.palette.danger.main} />}
						disabled={params.row.status !== "pending" || !isDeleteCmdAvail}
						onClick={showRemoveDialog(params.id)}
					/>,
				] : []
			),
		},
	] as const satisfies ReadonlyArray<GridColDef<GatewayHTTPT>>), [t, theme.palette.danger.main, isDeleteCmdAvail, showRemoveDialog]);

	if (error) {
		return <div>{error}</div>;
	}

	const isAddCmdAvail = Boolean(Gupport.setGatewayHttp);

	return (
		<>
			<Paper>
				<DataGrid
					loading={loading}
					columns={columns}
					rows={https}
					getRowId={(row) => (row.httpId)}
				/>
			</Paper>
			{isAddCmdAvail &&
				<Paper style={{ position: "relative", borderTop: "1px solid #9d9d9d", marginTop: "10px", padding: "15px" }}>
					<TextField
						name="httpCommand"
						id="httpCommand"
						autoFocus={true}
						placeholder={t("gateway.hHint")}
						onChange={onChange}
						value={command}
						style={{width: "90%"}}
					/>
					<div style={{ position: "absolute", right: 24, top: "50%", marginTop: "-24px" }}>
						<IconButton disabled={loading} onClick={() => sendHttpCommands(true)} style={{marginRight: "5px"}}>
							<Svg src="actions/hash.svg" color={theme.palette.primary.main} size={30} />
						</IconButton>
						<IconButton disabled={loading} onClick={() => sendHttpCommands(false)}>
							<Svg src="actions/erlang.svg" color={theme.palette.primary.main} />
						</IconButton>
					</div>
				</Paper>
			}
			<Dialog
				fullWidth={true}
				open={openDialog}
				onClose={handleClose}
			>
				<DialogTitle>{t("dialog.error")}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{action}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>{t("dialog.ok")}</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				fullWidth={true}
				open={openRemoveDialog}
				onClose={handleClose}
			>
				<DialogTitle>{t("gateway.hDialogTitle")}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{t("gateway.hDialogBody")}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={removeHttp}>{t("gateway.hRemove")}</Button>
					<Button color="inherit" onClick={handleClose}>{t("dialog.cancel")}</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default GatewayHTTP;
