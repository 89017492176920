import { useState, Fragment, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
	List,
	ListSubheader,
	ListItem,
	ListItemText,
	Divider,
	Snackbar,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Graph } from "react-d3-graph";
//cmp
import CustomNodeConfig from "./custom-node-config";
import CenterCircularProgress from "../../CenterCircularProgress";
import NetworkStrengthDialog from "./NetworkStrengthDialog";
// hooks
import useDevices from "../../../hooks/useDevices";
// services
import Gupport from "../../../services/gupport";
import Constants from "../../../services/constants";
import { Storage, StorageKeys } from "../../../services/storage";
import { sortAlphabetically } from "../../../services/l10n";
import { images } from "@local/theme";
// types
import type { DeviceId, DeviceObj } from "../../../types/device";
import type { RocIdData } from "../../../types/roc-table";
import type { CmdSendActionCmd, CmdGetTable, MsgResponseSendActionCmd } from "../../../types/gupport";
import type { CustomGraphNode, CustomGraphLink, CustomGraphLinkData } from "../../../types/misc";

interface GraphData {
	nodes: Array<CustomGraphNode>;
	links: Array<CustomGraphLink>;
}

const calulateWidth = () => {
	const parent = document.getElementById("container");
	return parent?.offsetWidth - 80 - 300;
};

const sortFunc = (deviceA: DeviceObj, deviceB: DeviceObj) => (
	sortAlphabetically(deviceA.name, deviceB.name)
);

const isZigbee = (device: DeviceObj) => (
	device.attributes[Constants.Device.Attributes.Property.DeviceType] === Constants.Device.Attributes.Value.DeviceType.ZigbeeActions
);

const isNotBatteryPowered = (device: DeviceObj) => (
	device.attributes[Constants.Device.Attributes.Property.PowerSource] !== Constants.Device.Attributes.Value.PowerSource.Battery
);

const getLineStyle = (relationship: string): number => {
	const solidLineRelationships = [Constants.ZigbeeDeviceNetworkInfo.RelationShips.Parent, Constants.ZigbeeDeviceNetworkInfo.RelationShips.Child, Constants.ZigbeeDeviceNetworkInfo.RelationShips.Sibling];
	return solidLineRelationships.includes(relationship) ? Constants.ZigbeeDeviceNetworkInfo.LineStyle.Solid : Constants.ZigbeeDeviceNetworkInfo.LineStyle.Dotted;
};

const getLinkRelationship = (relationship: string): string => {
	switch (relationship) {
		case Constants.ZigbeeDeviceNetworkInfo.RelationShips.Parent:
			return Constants.ZigbeeDeviceNetworkInfo.Notations.Parent;
		case Constants.ZigbeeDeviceNetworkInfo.RelationShips.Child:
			return Constants.ZigbeeDeviceNetworkInfo.Notations.Child;
		case Constants.ZigbeeDeviceNetworkInfo.RelationShips.Sibling:
			return Constants.ZigbeeDeviceNetworkInfo.Notations.Sibling;
		default:
			//Constants.ZigbeeDeviceNetworkInfo.RelationShips.Special1
			//Constants.ZigbeeDeviceNetworkInfo.RelationShips.Special2
			return Constants.ZigbeeDeviceNetworkInfo.Notations.Special;
	}
};

const getLinkLabel = (data: CustomGraphLinkData): string => (
	`${getLinkRelationship(data.relationship)} ${data.lqi}% ⇨`
);

const ZigbeeNetworkMap = () => {
	const theme = useTheme();
	const { t } = useTranslation();

	const rocIdTableDataRef = useRef<Array<RocIdData> | undefined>(undefined);

	const gatewayId = Storage.get(StorageKeys.gatewayId);
	const { loading, loaded, devices, hasError } = useDevices(gatewayId);

	const [networkMapLoading, setNetworkMapLoading] = useState(true);
	const [showCopySnackbar, setShowCopySnackbar] = useState(false);
	const [networkGraphInfo, setNetworkGraphInfo] = useState<GraphData | null>(null);

	const getDeviceIcon = (deviceId: DeviceId): string => {
		const device = devices.find((device) => (device.id === deviceId));
		if (device?.rocId) {
			const rocData = rocIdTableDataRef.current?.find((rocIdTableEntry) => (rocIdTableEntry.rocid === device.rocId));
			if (rocData?.icon) {
				return images(`devices/${rocData.icon}.svg`);
			}
		}
		return images("di_device.svg");
	};

	const copyMacId = (nodeId: string) => {
		navigator.clipboard.writeText(nodeId).then(() => {
			setShowCopySnackbar(true);
		}, (error) => {
			console.warn(error);
		});
	};

	useEffect(() => {
		if (!loading && loaded && gatewayId) {
			const fetchRocIdTable = () => (
				new Promise<void>((resolve, reject) => {
					const cmd = {
						action: "getTable",
						tableName: "rocid_dict",
					} as const satisfies CmdGetTable;
					Gupport.send(cmd, (error, msg) => {
						if (!error && msg?.payload.status === "ok") {
							rocIdTableDataRef.current = msg.payload.data.map((entry) => (entry.data));
							resolve();
						} else {
							reject(error);
						}
					});
				})
			);

			const fetchZigbeeNetworkInfo = () => {
				const tasks = devices.filter((device) => (isZigbee(device) && isNotBatteryPowered(device) && device.FF01 === "reachable")).map((device) => {
					const cmd = {
						action: "sendActionCmd",
						gatewayId: gatewayId,
						deviceId: device.id,
						endpoint: "01",
						caps: "incaps",
						clusterId: "0031",
						source: "user",
					} as const satisfies CmdSendActionCmd;
					return (
						new Promise<MsgResponseSendActionCmd>((resolve, reject) => {
							Gupport.send(cmd, (error, msg) => {
								if (error) {
									reject(error);
								} else {
									resolve(msg as MsgResponseSendActionCmd);
								}
							}, 60000);
						})
					);
				});
				Promise.allSettled(tasks).then((results) => {
					const errors = results.filter((result) => (result.status === "rejected")).map((result) => (result.reason));
					if (errors.length > 0) {
						console.warn("Fetch resources failed:", errors);
					}

					const networkMappingList = results.filter((result) => (result.status === "fulfilled")).flatMap((result) => (result.value.payload));
					const graphData = {
						nodes: [] as Array<CustomGraphNode>,
						links: [] as Array<CustomGraphLink>,
					} satisfies GraphData;
					networkMappingList.forEach((payload) => {
						const deviceId = payload.sourceAction.deviceId as string;
						if (!graphData.nodes.some((node) => (node.id === deviceId))) {
							const deviceName = devices.find((device) => (device.id === deviceId))?.name;
							const currentNode = {
								id: deviceId,
								name: deviceName ?? t("gateway.deviceMissing"),
								icon: getDeviceIcon(deviceId),
								isGateway: false,
								isListedDevice: Boolean(deviceName),
								copyMacId: copyMacId,
								x: Math.floor(Math.random() * 800),
								y: Math.floor(Math.random() * 800),
							} as const satisfies CustomGraphNode;
							graphData.nodes.push(currentNode);
						}
						payload.data.forEach((item) => {
							if (!graphData.nodes.some((node) => (node.id === item.mac))) {
								const isGateway = item.mac === gatewayId.replace(/:/g, "");
								const deviceName = devices.find((device) => (device.id === item.mac))?.name;
								const currentNode = {
									id: item.mac,
									name: isGateway ? t("gateway.networkMap.gateway") : deviceName ?? t("gateway.deviceMissing"),
									icon: isGateway ? images("di_gateway.svg") : getDeviceIcon(item.mac),
									isGateway: isGateway,
									isListedDevice: Boolean(deviceName),
									copyMacId: copyMacId,
									x: isGateway ? undefined : Math.floor(Math.random() * 800),
									y: isGateway ? undefined : Math.floor(Math.random() * 800),
								} as const satisfies CustomGraphNode;
								graphData.nodes.push(currentNode);
							}
							const data = {
								lqi: Math.round(Number.parseInt(item.lqi, 16) / 255 * 100),
								lqiRaw: item.lqi,
								lqiAbs: Number.parseInt(item.lqi, 16),
								relationship: item.relationship,
							} as const satisfies CustomGraphLinkData;
							graphData.links.push({ source: item.mac, target: deviceId, label: getLinkLabel(data), strokeDasharray: Constants.ZigbeeDeviceNetworkInfo.LineStyle.Solid, data: data });
						});
					});

					graphData.links.forEach((link) => {
						const foundLink = graphData.links.find((linkT) => (linkT.source === link.target && linkT.target === link.source));
						if (foundLink) {
							if (foundLink.data.relationship === link.data.relationship) {
								foundLink.strokeDasharray = getLineStyle(link.data.relationship);
								link.strokeDasharray = getLineStyle(link.data.relationship);
							} else if ([foundLink.data.relationship, link.data.relationship].includes(Constants.ZigbeeDeviceNetworkInfo.RelationShips.Parent) &&
								[foundLink.data.relationship, link.data.relationship].includes(Constants.ZigbeeDeviceNetworkInfo.RelationShips.Child)) {
								foundLink.strokeDasharray = Constants.ZigbeeDeviceNetworkInfo.LineStyle.Solid;
								link.strokeDasharray = Constants.ZigbeeDeviceNetworkInfo.LineStyle.Solid;
							} else {
								foundLink.strokeDasharray = Constants.ZigbeeDeviceNetworkInfo.LineStyle.Dashed;
								link.strokeDasharray = Constants.ZigbeeDeviceNetworkInfo.LineStyle.Dashed;
							}
						} else {
							link.strokeDasharray = getLineStyle(link.data.relationship);
						}
					});
					setNetworkMapLoading(false);
					setNetworkGraphInfo(graphData);
				}).catch(() => {
					// Promise.allSettled() never failed
				});
			};
			void (async () => {
				await fetchRocIdTable();
				fetchZigbeeNetworkInfo();
			})();
		} else if (hasError) {
			setNetworkMapLoading(false);
		}

		return () => {
			rocIdTableDataRef.current = undefined;
		};
	}, [gatewayId, loaded, loading]);

	const disconnectedZigbeeDevices = (!networkMapLoading && networkGraphInfo)
		? devices.filter((device) => (isZigbee(device) && !networkGraphInfo.nodes.some((node) => (node.id === device.id)))).sort(sortFunc)
		: [];

	return (
		<>
			{networkMapLoading
				? <CenterCircularProgress />
				: (!networkGraphInfo || networkGraphInfo.nodes.length === 0)
					? t("gateway.networkMap.noInfo")
					: (
						<div>
							<div style={{ display: "flex", maxHeight: "700px", width: "auto" }}>
								<div style={{ width: calulateWidth() }}>
									<Graph
										id="zigbee-network-topology"
										data={networkGraphInfo}
										config={{ ...CustomNodeConfig, width: calulateWidth() - 300, link: { ...CustomNodeConfig.link, fontColor: theme.palette.text.primary, color: theme.palette.grey[600] } }}
									/>
									<div style={{ display: "flex", flexDirection: "row" }}>
										<div className="network-map-legend">
											<div>
												<svg width="40" height="3" viewBox="0 0 40 3" xmlns="http://www.w3.org/2000/svg">
													<line x1="0" y1="1" x2="40" y2="1" stroke="black" />
												</svg>
												{t("gateway.networkMap.one2oneMapping")}
											</div>
											<div>
												<svg width="40" height="3" viewBox="0 0 40 3" xmlns="http://www.w3.org/2000/svg">
													<line x1="0" y1="1" x2="40" y2="1" stroke="black" strokeDasharray="4" />
												</svg>
												{t("gateway.networkMap.mixedMapping")}
											</div>
											<div>
												<svg width="40" height="3" viewBox="0 0 40 3" xmlns="http://www.w3.org/2000/svg">
													<line x1="0" y1="1" x2="40" y2="1" stroke="black" strokeDasharray="1 2" />
												</svg>
												{t("gateway.networkMap.specialMapping")}
											</div>
											<div><strong>{Constants.ZigbeeDeviceNetworkInfo.Notations.Parent}</strong>{t("generic.chars.equalTo")}{t("gateway.networkMap.parent")}{t("generic.chars.comma")} <strong>{Constants.ZigbeeDeviceNetworkInfo.Notations.Child}</strong>{t("generic.chars.equalTo")}{t("gateway.networkMap.child")}</div>
											<div><strong>{Constants.ZigbeeDeviceNetworkInfo.Notations.Sibling}</strong>{t("generic.chars.equalTo")}{t("gateway.networkMap.sibling")}{t("generic.chars.comma")}<strong>{Constants.ZigbeeDeviceNetworkInfo.Notations.Special}</strong>{t("generic.chars.equalTo")}{t("gateway.networkMap.special")}</div>
										</div>
									</div>
								</div>
								{disconnectedZigbeeDevices.length > 0 &&
									<List
										className="disconnected-devices-list"
										subheader={<ListSubheader disableSticky={true}>{t("gateway.disconnectedDevices")}</ListSubheader>}
										style={{ maxHeight: "700px", overflow: "auto", border: "1px solid #a9a9a9" }}
									>
										{disconnectedZigbeeDevices.map((node) => (
											<Fragment key={node.id}>
												<Divider />
												<ListItem>
													<ListItemText
														primary={node.name ?? node.id}
														secondary={node.id}
														slotProps={{
															primary: {
																noWrap: true,
															},
														}}
													/>
												</ListItem>
											</Fragment>
										))}
									</List>
								}
							</div>
							{(gatewayId && loaded) && <NetworkStrengthDialog gatewayId={gatewayId} devices={devices} />}
						</div>
					)
			}
			<Snackbar
				open={showCopySnackbar}
				message={t("gateway.nodeCopyMsg")}
				autoHideDuration={3000}
				onClose={() => (setShowCopySnackbar(false))}
			/>
		</>
	);
};

export default ZigbeeNetworkMap;
