import {
	ArcElement,
	DoughnutController,
	Legend,
	Title,
	Tooltip,
} from "chart.js";
import "chartjs-adapter-date-fns";
import { ReactChart } from "chartjs-react";
// types
import type { ChartType, ChartData, ChartOptions } from "chart.js";

const CHART_TYPE = "doughnut" satisfies ChartType;

type Props = Readonly<{
	data: ChartData<typeof CHART_TYPE>;
	options: ChartOptions<typeof CHART_TYPE>;
}>;

ReactChart.register(
	Legend,
	ArcElement,
	Title,
	DoughnutController,
	Tooltip
);

const DoughnutChart = ({ data, options }: Props) => (
	<ReactChart
		id="doughnut-chart"
		type={CHART_TYPE}
		data={data}
		options={options}
	/>
);

export default DoughnutChart;
