import { memo } from "react";
import { BarChart } from "@mui/x-charts/BarChart";

type Props = Readonly<{
	chartData: object;
}>;

const getColors = (chartData) => (Object.keys(chartData).map((key) => {
	const strength = Math.round(chartData[key] / 255 * 100);
	if (strength >= 75) {
		return "#e15046";
	} else if (strength >= 25) {
		return "#e1b046";
	}
	return "#46e164";
}));

const BarChartGraph = ({ chartData }: Props) => (
	<BarChart
		height={180}
		margin={{ top: 20, left: 0, right: 20, bottom: 50 }}
		leftAxis={null}
		barLabel={(barItem) => (`${barItem.value}%`)}
		borderRadius={4}
		series={[{ data: Object.keys(chartData).map((key) => (Math.round(chartData[key] / 255 * 100))) }]}
		xAxis={[{
			data: Object.keys(chartData),
			colorMap: {
				type: "ordinal",
				colors: getColors(chartData),
			},
			scaleType: "band" as const,
			tickLabelStyle: { fill: "#000000" },
		}]}
		yAxis={[
			{
				max: 100,
			},
		]}
	/>
);

export default memo(BarChartGraph);
