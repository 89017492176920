import { useState, useEffect, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
	Skeleton,
	Grid2 as Grid,
	Paper,
	Table,
	TableHead,
	TableBody,
	TableFooter,
	TableRow,
	TableCell,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	Snackbar,
	DialogActions,
	Switch,
} from "@mui/material";
import { green, indigo, orange, grey } from "@mui/material/colors";
import { GridActionsCellItem, DataGrid } from "@mui/x-data-grid";
// cmp
import Na from "../na";
import Doughnut from "../../cmp/charts/doughnut-chart";
// contexts
import { useUserData } from "../../context/UserDataContext";
// services
import Gupport from "../../services/gupport";
import Constants from "../../services/constants";
import { icons } from "@local/theme";
// types
import type { ChartData, ChartOptions, ChartEvent, LegendItem, ActiveElement } from "chart.js";
import type { GatewayId } from "../../types/gateway";
import type { RuleType, Rules, Rule } from "../../types/rule";
import type { CmdGatewayActionGatewayGetRules } from "../../types/gupport";
import type { GridRowId, GridColDef, GridFilterItem, GridFilterModel } from "@mui/x-data-grid";

const RULE_TYPES = [
	{
		type: Constants.Rule.Type.Advanced,
		name: "gateway.advanced",
		color: green[500],
	},
	{
		type: Constants.Rule.Type.Template,
		name: "gateway.template",
		color: indigo[500],
	},
	{
		type: Constants.Rule.Type.Scheduler,
		name: "gateway.scheduler",
		color: orange[500],
	},
] as const;

type Props = Readonly<{
	gatewayId: GatewayId;
}>;

const GatewayRules = ({ gatewayId }: Props) => {
	const { t } = useTranslation();

	const { ready } = useUserData();

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [rules, setRules] = useState<Rules>([]);
	const [rule, setRule] = useState<Rule | undefined>(undefined);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [showCopySnackbar, setShowCopySnackbar] = useState(false);
	const [ruleTypeFilter, setRuleTypeFilter] = useState<RuleType | null>(null);
	const [gridFilter, setGridFilter] = useState<Array<GridFilterItem>>([]);

	useEffect(() => {
		if (ready) {
			setLoading(true);

			const cmd = {
				action: "gatewayAction",
				module: "gateway",
				function: "getRules",
				params: [],
				gatewayId: gatewayId,
			} as const satisfies CmdGatewayActionGatewayGetRules;
			Gupport.send(cmd, (_error, msg) => {
				if (!_error && msg?.payload.status === "ok") {
					setError(false);
					setRules(msg.payload.data);
				} else {
					setError(true);
					setRules([]);
				}
				setLoading(false);
			});
		}
	}, [gatewayId, ready]);

	const handleOnFilterModelChange = (filter: GridFilterModel) => {
		const filterItem = filter.items.find((item) => (item.field === "type" && item.operator === "is"));
		if (filter.items.length === 0 || filterItem?.value === undefined) {
			setRuleTypeFilter(null);
		} else if (Object.values(Constants.Rule.Type).includes(filterItem.value)) {
			setRuleTypeFilter(filterItem.value);
		}
		setGridFilter(filter.items);
	};

	const handleShowJsonClick = useCallback((id: GridRowId) => (
		() => {
			setRule(rules.find((rule) => (rule.id === id)));
			setDialogOpen(true);
		}
	), [rules]);

	const columns = useMemo(() => ([
		{
			field: "id",
			headerName: t("gateway.ruleId"),
			flex: 4,
			renderCell: (params) => (<pre style={{ margin: 0 }}>{params.value}</pre>),
		},
		{
			field: "name",
			headerName: t("gateway.ruleName"),
			flex: 6,
			renderCell: (params) => (params.value ?? <Na />),
		},
		{
			field: "enabled",
			headerName: t("gateway.ruleEnabled"),
			flex: 2,
			type: "boolean",
			renderCell: (params) => (<Switch checked={params.value} disabled={true} />),
		},
		{
			field: "type",
			headerName: t("gateway.ruleType"),
			flex: 2,
			type: "singleSelect",
			valueOptions: Object.values(Constants.Rule.Type),
			renderCell: (params) => (
				params.value
					? <span style={{ color: RULE_TYPES.find((ruleType) => (ruleType.type === params.value))?.color }}>{params.value}</span>
					: <Na />
			),
		},
		{
			field: "actions",
			headerName: t("gateway.raw"),
			type: "actions",
			flex: 1,
			getActions: (params) => ([
				<GridActionsCellItem
					key={params.id}
					label={t("gateway.raw")}
					icon={<icons.RawOn />}
					onClick={handleShowJsonClick(params.id)}
				/>,
			]),
		},
	] as const satisfies ReadonlyArray<GridColDef<Rule>>), [t, handleShowJsonClick]);

	if (error) {
		return <div>{t("gateway.noRules")}</div>;
	}

	const handleCopyClick = () => {
		const json = JSON.stringify(rule, null, 2);
		navigator.clipboard.writeText(json).then(() => {
			setShowCopySnackbar(true);
		}, (_error) => {
			console.warn(_error);
		});
	};

	const ruleTypes = RULE_TYPES.map((ruleType) => ({
		...ruleType,
		count: rules.filter((rule) => (rule.type === ruleType.type)).length,
	}));

	const handleLegendClick = (event: ChartEvent, legendItem: LegendItem/*, legend: LegendElement<"doughnut">*/) => {
		const type = ruleTypes[legendItem.index].type;
		if (ruleTypeFilter === type) {
			setGridFilter([]);
			setRuleTypeFilter(null);
		} else {
			setGridFilter([{ field: "type", operator: "is", value: type }]);
			setRuleTypeFilter(type);
		}
	};

	const handleDoughnutClick = (event: ChartEvent, elements: Array<ActiveElement>/*, chart: Chart*/) => {
		const type = ruleTypes[elements[0].index].type;
		if (ruleTypeFilter === type) {
			setGridFilter([]);
			setRuleTypeFilter(null);
		} else {
			setGridFilter([{ field: "type", operator: "is", value: type }]);
			setRuleTypeFilter(type);
		}
	};

	const handleTableRowClick = (type: RuleType | null) => {
		if (ruleTypeFilter === type || type === null) {
			setGridFilter([]);
			setRuleTypeFilter(null);
		} else {
			setGridFilter([{ field: "type", operator: "is", value: type }]);
			setRuleTypeFilter(type);
		}
	};

	const data = {
		datasets: [{
			data: ruleTypes.map((ruleType) => (ruleType.count)),
			backgroundColor: (ruleTypeFilter === null)
				? ruleTypes.map((ruleType) => (ruleType.color))
				: ruleTypes.map((ruleType) => ((ruleType.type === ruleTypeFilter) ? ruleType.color : grey[400])),
			label: t("gateway.rules"),
		}],
		labels: ruleTypes.map((ruleType) => (t(ruleType.name))),
	} satisfies ChartOptions<"doughnut">;

	const options = {
		animation: false,
		layout: {
			padding: 16,
		},
		cutout: "70%",
		plugins: {
			legend: {
				display: true,
				position: "top",
				onClick: handleLegendClick,
			},
		},
		onClick: handleDoughnutClick,
	} as const satisfies ChartData<"doughnut">;

	return (
		<>
			<Grid container={true} spacing={1}>
				<Grid size={{ xs: 2 }}>
					<Paper>
						{loading ? <Skeleton variant="rectangular" height="225px" /> : <Doughnut data={data} options={options} />}
						<Table size="small">
							<TableHead>
								<TableRow>
									<TableCell>{t("gateway.ruleType")}</TableCell>
									<TableCell align="right">{t("gateway.count")}</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{ruleTypes.map((ruleType) => (
									<TableRow
										key={ruleType.type}
										hover={true}
										selected={ruleTypeFilter === ruleType.type}
										onClick={() => (handleTableRowClick(ruleType.type))}
										style={{ cursor: "pointer" }}
									>
										<TableCell>{t(ruleType.name)}</TableCell>
										<TableCell align="right">{ruleType.count}</TableCell>
									</TableRow>
								))}
							</TableBody>
							<TableFooter>
								<TableRow className="last-row-no-border" onClick={() => (handleTableRowClick(null))} style={{ cursor: "pointer" }}>
									<TableCell variant="head">{t("gateway.total")}</TableCell>
									<TableCell variant="head" align="right">{rules.length}</TableCell>
								</TableRow>
							</TableFooter>
						</Table>
					</Paper>
				</Grid>
				<Grid size={{ xs: 10 }}>
					<Paper>
						<DataGrid
							loading={loading}
							columns={columns}
							rows={rules}
							filterModel={{
								items: gridFilter,
							}}
							onFilterModelChange={handleOnFilterModelChange}
							sx={{
								"& .MuiDataGrid-actionsCell svg": {
									width: "26px",
									height: "26px",
								},
							}}
						/>
					</Paper>
				</Grid>
			</Grid>
			<Dialog
				fullWidth={true}
				maxWidth="md"
				open={dialogOpen}
				onClose={() => setDialogOpen(false)}
			>
				<DialogTitle>{t("gateway.deviceInfoDialogTitle")}</DialogTitle>
				<DialogContent>
					<Button variant="contained" onClick={handleCopyClick}>
						{t("gateway.copy")}
					</Button>
					<pre>{JSON.stringify(rule, null, 2)}</pre>
				</DialogContent>
				<DialogActions>
					<Button color="inherit" onClick={() => setDialogOpen(false)}>{t("dialog.close")}</Button>
				</DialogActions>
			</Dialog>
			<Snackbar
				open={showCopySnackbar}
				message={t("gateway.copyMsg")}
				autoHideDuration={3000}
				onClose={() => setShowCopySnackbar(false)}
			/>
		</>
	);
};

export default GatewayRules;
