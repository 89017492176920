import { useState, useEffect, memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
	Button,
	Card,
	CardContent,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	CardHeader,
	Paper,
	TextField,
	FormControl,
	InputLabel,
	MenuItem,
	ListItemText,
	Checkbox,
	Select,
	Snackbar,
} from "@mui/material";
// cmps
import BarChartGraph from "../../charts/bar-chart-graph";
// services
import { getSocketId } from "../../../services/authenticator";
import Gupport from "../../../services/gupport";
import Constants from "../../../services/constants";
import { decimal2Hex } from "../../../services/utils";
// types
import type { SelectChangeEvent } from "@mui/material";
import type { DeviceId, DeviceObjs } from "../../../types/device";
import type { GatewayId } from "../../../types/gateway";
import type { CmdGatewayActionGatewayGetZigbeeRoutersNoise, GupportMessageRxKnown } from "../../../types/gupport";

type NetworkStrenghData = Record<number, number>;

type Props = Readonly<{
	gatewayId: GatewayId;
	devices: DeviceObjs;
}>;

const ALL_CHANNELS = "All";

const NetworkStrengthDialog = ({ devices, gatewayId }: Props) => {
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);
	const [isScanning, setIsScanning] = useState(false);
	const [scanCount, setScanCount] = useState(1);
	const [scanDuration, setScanDuration] = useState(1);
	const [selectedChannels, setSelectedChannels] = useState<Array<string>>([]);
	const [zigbeeChannelInfo, setZigbeeChannelInfo] = useState<Record<DeviceId, NetworkStrenghData> | undefined>(undefined);
	const [showGenericErrorMsg, setShowGenericErrorMsg] = useState(false);

	useEffect(() => {
		const handleGupportMessage = (msg: GupportMessageRxKnown) => {
			if (msg.payload?.info === "get_zigbee_routers_noise") {
				if (msg.error) {
					setShowGenericErrorMsg(true);
					setIsScanning(false);
					setOpen(false);
				} else if (msg.payload?.status === "ok") {
					setShowGenericErrorMsg(false);
					setZigbeeChannelInfo(msg.payload.data);
					setOpen(true);
					setIsScanning(false);
				}
			}
		};

		Gupport.on("message-rx", handleGupportMessage);

		return () => {
			Gupport.on("message-rx", handleGupportMessage);
		};
	}, []);

	const handleStartScan = useCallback(() => {
		setIsScanning(true);

		let channelsMask = 0;
		if (selectedChannels.includes(ALL_CHANNELS)) {
			channelsMask = Constants.ZigbeeChannels.find((channel) => (channel.value === ALL_CHANNELS))?.bitFlag ?? 0;
		} else {
			Constants.ZigbeeChannels.filter((channel) => (selectedChannels.includes(channel.value))).forEach((channel) => {
				channelsMask |= channel.bitFlag;
			});
		}

		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const glientId = getSocketId()!;

		const cmd = {
			action: "gatewayAction",
			module: "gateway",
			function: "get_zigbee_routers_noise",
			gatewayId: gatewayId,
			params: [{
				glientId: glientId,
				channelsMask: decimal2Hex(channelsMask, 8),
				scanCount: decimal2Hex(scanCount, 4),
				scanDuration: decimal2Hex(scanDuration, 2),
			}],
			glientId: glientId,
		} as const satisfies CmdGatewayActionGatewayGetZigbeeRoutersNoise;
		Gupport.send(cmd);
	}, [gatewayId, selectedChannels, scanCount, scanDuration]);

	const handleChange = useCallback((event: SelectChangeEvent<Array<string>>) => {
		const value = event.target.value as Array<string>;
		if (value.includes(ALL_CHANNELS)) {
			if (selectedChannels.includes(ALL_CHANNELS) && value.length !== Constants.ZigbeeChannels.length) {
				setSelectedChannels((prevSelectedChannels) => (prevSelectedChannels.filter((channel) => (channel !== ALL_CHANNELS && value.includes(channel)))));
			} else {
				setSelectedChannels(Constants.ZigbeeChannels.map((channel) => (channel.value)));
			}
		} else if (selectedChannels.includes(ALL_CHANNELS)) {
			setSelectedChannels([]);
		} else {
			setSelectedChannels(value);
		}
	}, [selectedChannels]);

	const handleClose = useCallback(() => {
		setOpen(false);
		setSelectedChannels([]);
	}, []);

	return (
		<>
			<div style={{ display: "flex", width: "1000px", justifyContent: "center" }}>
				<Paper sx={{ padding: "8px", width: "70%", display: "flex", justifyContent: "space-around" }}>
					<TextField
						label={t("gateway.networkMap.count")}
						type="number"
						slotProps={{
							htmlInput: {
								min: 1,
								max: 5,
								step: 1,
								inputMode: "numeric",
								style: {
									textAlign: "right",
								},
							},
						}}
						value={scanCount}
						onChange={((event) => (setScanCount(Number(event.target.value))))}
						sx={{ width: "160px" }}
					/>
					<TextField
						label={t("gateway.networkMap.duration")}
						type="number"
						slotProps={{
							htmlInput: {
								min: 1,
								step: 1,
								inputMode: "numeric",
								style: {
									textAlign: "right",
								},
							},
						}}
						value={scanDuration}
						onChange={((event) => (setScanDuration(Number(event.target.value))))}
						sx={{ width: "160px" }}
					/>
					<FormControl sx={{ width: "30%" }}>
						<InputLabel id="channels">{t("gateway.networkMap.channels")}</InputLabel>
						<Select<Array<string>>
							labelId="channels"
							id="demo-multiple-checkbox"
							multiple={true}
							value={selectedChannels}
							onChange={handleChange}
							renderValue={(selected) => selected.join(", ")}
						>
							{Constants.ZigbeeChannels.map(({ value }) => (
								<MenuItem key={value} value={value}>
									<Checkbox checked={selectedChannels.includes(value)} />
									<ListItemText primary={value} />
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<Button
						variant="contained"
						disabled={selectedChannels.length === 0}
						loading={isScanning}
						onClick={handleStartScan}
					>
						{t("gateway.networkMap.start")}
					</Button>
				</Paper>
			</div>
			{zigbeeChannelInfo &&
				<Dialog
					fullWidth={true}
					maxWidth="lg"
					open={open}
					onClose={handleClose}
				>
					<DialogTitle>{t("gateway.networkMap.strengthDialogTitle")}</DialogTitle>
					<DialogContent sx={{ display: "flex", flexWrap: "wrap" }}>
						{Object.keys(zigbeeChannelInfo).map((key) => (
							<Card key={key} sx={{ backgroundColor: "#8fbc8f", m: "8px", flexGrow: 1, flexBasis: 300 }}>
								<CardHeader
									title={devices.find((device) => (device.id === key))?.name}
									subheader={key}
									slotProps={{
										title: {
											noWrap: true,
											variant: "h6",
											style: {
												fontWeight: "bold",
												color: "rgba(0, 0, 0, 0.870588)",
											},
										},
										subheader: {
											noWrap: true,
											variant: "body2",
											style: {
												color: "rgba(0, 0, 0, 0.870588)",
											},
										},
									}}
								/>
								<CardContent>
									<BarChartGraph chartData={zigbeeChannelInfo[key]} />
								</CardContent>
							</Card>
						))}
					</DialogContent>
					<DialogActions>
						<Button color="inherit" onClick={handleClose}>{t("dialog.close")}</Button>
					</DialogActions>
				</Dialog>
			}
			<Snackbar
				open={showGenericErrorMsg}
				message={t("gateway.networkMap.genericErrorMsg")}
				autoHideDuration={3000}
				onClose={() => (setShowGenericErrorMsg(false))}
			/>
		</>
	);
};

export default memo(NetworkStrengthDialog);
