import { Component, createRef } from "react";
import { Navigate } from "react-router";
import { withTranslation } from "react-i18next";
import { Tab, Paper } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
// cmp
import withNavigateAndParams from "../withNavigateAndParams";
import GatewayGeneralInfo from "./GatewayGeneralInfo";
import GatewayAdvancedInfo from "./GatewayAdvancedInfo";
import GatewayUsers from "./gateway-users";
import GatewayDevices from "./gateway-devices";
import GatewayRules from "./gateway-rules";
import GatewaySubscribers from "./GatewaySubscribers";
import GatewayTerminal from "./gateway-terminal";
import GatewayHTTP from "./GatewayHttp";
import GatewayLogs from "./GatewayLogs";
// services
import User from "../../services/user";
import { TerminalType } from "../../services/terminal";
import { Storage, StorageKeys } from "../../services/storage";
// types
import type { WithTranslation } from "react-i18next";
import type { ReactRouterProps } from "../../types/misc";
import type { GatewayId, GatewayName } from "../../types/gateway";

type Props = Readonly<WithTranslation & ReactRouterProps & {
	gatewayId: GatewayId;
	gatewayLabel: GatewayName | GatewayId;
}>;

type State = {
	selectedTab: any;
};

const TAB_NAMES = {
	GENERAL: "general",
	ADVANCED: "advanced",
	USERS: "users",
	DEVICES: "devices",
	RULES: "rules",
	SUBSCRIBERS: "subscribers",
	CCC: "ccc",
	SHELL: "shell",
	HTTP: "http",
	LOGS: "logs",
} as const;

class GatewayTabs extends Component<Props, State> {

	#hasCCCREAD = User.hasLevel("ccc_read");

	#references = {
		general: createRef(),
		advanced: createRef(),
		users: null,
		devices: null,
		rules: null,
		subscribers: createRef(),
		ccc: createRef(),
		shell: createRef(),
		http: null,
		logs: createRef(),
	};

	#timeout: number | null = null;

	constructor(props: Props) {
		super(props);

		let tab;
		if (this.props.params.tab) {
			Storage.set(StorageKeys.gatewaysTab, this.props.params.tab);
			tab = this.props.params.tab;
		} else if (Storage.get(StorageKeys.gatewaysTab)) {
			tab = Storage.get(StorageKeys.gatewaysTab);
		} else {
			tab = TAB_NAMES.GENERAL;
		}

		this.state = {
			// ready: Gupport.ready,
			selectedTab: tab,
		};

		this.handleTabChanged = this.handleTabChanged.bind(this);
	}

	override componentDidMount() {
		this.refresh();
	}

	override componentDidUpdate(prevProps) {
		if (this.#timeout) {
			window.clearTimeout(this.#timeout);
		}
		if (this.props.params.tab && this.props.params.tab !== prevProps.params.tab) {
			const tab = this.props.params.tab;
			Storage.set(StorageKeys.gatewaysTab, tab);
			this.setState({
				selectedTab: tab,
			}, () => {
				this.refresh();
			});
		}
	}

	override componentWillUnmount() {
		if (this.#timeout) {
			window.clearTimeout(this.#timeout);
		}
	}

	refresh() {
		this.#timeout = window.setTimeout(() => {
			this.#references[this.state.selectedTab]?.current?.refresh?.();
			this.refresh();
		}, 30_000);
	}

	handleTabChanged(event, value) {
		if (this.#timeout) {
			window.clearTimeout(this.#timeout);
		}
		void this.props.navigate(`/gateways/${encodeURIComponent(this.props.gatewayId)}/${value}`);
	}

	override render() {
		const { t, params, gatewayId, gatewayLabel } = this.props;

		if (params.gatewayId === undefined || params.tab === undefined) {
			if ((!this.#hasCCCREAD && (this.state.selectedTab === "ccc" || this.state.selectedTab === "shell")) ||
				this.state.selectedTab === "http" ||
				this.state.selectedTab === "logs") {
				return <Navigate to={`/gateways/${encodeURIComponent(gatewayId)}/general`} replace={true} />;
			} else {
				return <Navigate to={`/gateways/${encodeURIComponent(gatewayId)}/${this.state.selectedTab}`} replace={true} />;
			}
		}

		return (
			<TabContext value={this.state.selectedTab}>
				<Paper style={{ marginBottom: "12px" }}>
					<TabList onChange={this.handleTabChanged}>
						<Tab label={t("gateway.info")} value={TAB_NAMES.GENERAL} />
						<Tab label={t("gateway.advanced")} value={TAB_NAMES.ADVANCED} />
						<Tab label={t("gateway.users")} value={TAB_NAMES.USERS} />
						<Tab label={t("gateway.devices")} value={TAB_NAMES.DEVICES} />
						<Tab label={t("gateway.rules")} value={TAB_NAMES.RULES} />
						<Tab label={t("gateway.subs")} value={TAB_NAMES.SUBSCRIBERS} />
						{this.#hasCCCREAD && <Tab label={t("gateway.ccc")} value={TAB_NAMES.CCC} />}
						{this.#hasCCCREAD && <Tab label={t("gateway.shell")} value={TAB_NAMES.SHELL} />}
						<Tab label={t("gateway.http")} value={TAB_NAMES.HTTP} />
						<Tab label={t("gateway.logs")} value={TAB_NAMES.LOGS} />
					</TabList>
				</Paper>
				<TabPanel value={TAB_NAMES.GENERAL}><GatewayGeneralInfo ref={this.#references.general} gatewayId={gatewayId} /></TabPanel>
				<TabPanel value={TAB_NAMES.ADVANCED}><GatewayAdvancedInfo ref={this.#references.advanced} gatewayId={gatewayId} /></TabPanel>
				<TabPanel value={TAB_NAMES.USERS}><GatewayUsers gatewayId={gatewayId} gatewayLabel={gatewayLabel || gatewayId} /></TabPanel>
				<TabPanel value={TAB_NAMES.DEVICES}><GatewayDevices gatewayId={gatewayId} /></TabPanel>
				<TabPanel value={TAB_NAMES.RULES}><GatewayRules gatewayId={gatewayId} /></TabPanel>
				<TabPanel value={TAB_NAMES.SUBSCRIBERS}><GatewaySubscribers ref={this.#references.subscribers} gatewayId={gatewayId} /></TabPanel>
				{this.#hasCCCREAD && <TabPanel value={TAB_NAMES.CCC}><GatewayTerminal ref={this.#references.ccc} gatewayId={gatewayId} module={TerminalType.CCC} /></TabPanel>}
				{this.#hasCCCREAD && <TabPanel value={TAB_NAMES.SHELL}><GatewayTerminal ref={this.#references.shell} gatewayId={gatewayId} module={TerminalType.SHELL} /></TabPanel>}
				<TabPanel value={TAB_NAMES.HTTP}><GatewayHTTP gatewayId={gatewayId} /></TabPanel>
				<TabPanel value={TAB_NAMES.LOGS}><GatewayLogs ref={this.#references.logs} gatewayId={gatewayId} /></TabPanel>
			</TabContext>
		);
	}

}

export default withTranslation()(withNavigateAndParams(GatewayTabs));
