import { useState, useEffect } from "react";
// contexts
import { useUserData } from "../context/UserDataContext";
// services
import Gupport from "../services/gupport";
// types
import type { CmdGetDevices } from "../types/gupport";
import type { GatewayId } from "../types/gateway";
import type { DeviceObjs } from "../types/device";

export const useDevices = (gatewayId: GatewayId) => {
	const { ready } = useUserData();

	const [loaded, setLoaded] = useState(false);
	const [loading, setLoading] = useState(true);
	const [hasError, setHasError] = useState(false);
	const [devices, setDevices] = useState<DeviceObjs>([]);

	useEffect(() => {
		if (ready) {
			setLoading(true);
			const cmd = {
				action: "getDevices",
				gatewayId: gatewayId,
			} as const satisfies CmdGetDevices;
			Gupport.send(cmd, (error, msg) => {
				if (!error && msg?.payload.status === "ok") {
					setHasError(false);
					setDevices(msg.payload.data);
				} else {
					setHasError(true);
					setDevices([]);
				}
				setLoaded(true);
				setLoading(false);
			});
		}
	}, [ready]);

	return { loaded, loading, devices, hasError } as const;
};

export default useDevices;
