import CustomNode from "./custom-node";
// types
import type { GraphConfiguration } from "react-d3-graph";
import type { CustomGraphNode, CustomGraphLink } from "../../../types/misc";

const customNodeConfig = {
	automaticRearrangeAfterDropNode: true,
	collapsible: true,
	directed: true,
	height: 700,
	highlightDegree: 2,
	highlightOpacity: 0.2,
	linkHighlightBehavior: true,
	initialZoom: 0.6,
	maxZoom: 12,
	minZoom: 0.05,
	nodeHighlightBehavior: true,
	panAndZoom: false,
	staticGraph: false,
	width: 1000,
	focusZoom: 4,
	d3: {
		alphaTarget: 0.05,
		gravity: -250,
		linkLength: 120,
		linkStrength: 2,
		disableLinkForce: false,
	},
	node: {
		color: "#d3d3d3",
		fontColor: "black",
		fontSize: 12,
		fontWeight: "normal",
		highlightColor: "red",
		highlightFontSize: 12,
		highlightFontWeight: "bold",
		highlightStrokeColor: "SAME",
		highlightStrokeWidth: 1.5,
		labelProperty: "name",
		mouseCursor: "pointer",
		opacity: 1,
		renderLabel: false,
		size: {
			width: 3000,
			height: 900,
		},
		strokeColor: "none",
		strokeWidth: 1.5,
		svg: "",
		symbolType: "square",
		viewGenerator: (node) => (<CustomNode node={node} />),
	},
	link: {
		fontSize: 12,
		fontWeight: "bold",
		highlightColor: "red",
		highlightFontSize: 12,
		renderLabel: true,
		highlightFontWeight: "bold",
		highlightStrokeWidth: 2,
		semanticStrokeWidth: true,
		strokeWidth: 1.5,
		markerHeight: 6,
		markerWidth: 6,
		strokeLinecap: "butt",
	},
} satisfies Partial<GraphConfiguration<CustomGraphNode, CustomGraphLink>>;

export default customNodeConfig;
