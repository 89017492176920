import { Component } from "react";
import { withTranslation } from "react-i18next";
import { CircularProgress, Paper } from "@mui/material";
// cmps
import Terminal from "../terminal/terminal";
// services
import Constants from "../../services/constants";
import CC from "../../services/cc";
import User from "../../services/user";
import { TerminalType, TerminalEntryType } from "../../services/terminal";
// types
import type { WithTranslation } from "react-i18next";
import type { GatewayId } from "../../types/gateway";
import type { CcMessageTxKnown, CcPayloadTxKnown, CmdCc, CcMessageRxKnown, CcPayloadRxKnown } from "../../types/cc";
import type { TerminalType as TerminalTypeT, TerminalEntries, TerminalEntry, Direction } from "../../types/terminal";

type Props = Readonly<WithTranslation & {
	gatewayId: GatewayId;
	module: TerminalTypeT;
}>;

type State = {
	ready: boolean;
	connecting: boolean;
	disconnecting: boolean;
	terminalEntries: TerminalEntries;
};

class GatewayTerminal extends Component<Props, State> {

	#cc = new CC();

	constructor(props: Props) {
		super(props);

		this.state = {
			ready: this.#cc.ready,
			connecting: this.#cc.connecting,
			disconnecting: this.#cc.disconnecting,
			terminalEntries: [],
		};

		this.handleReady = this.handleReady.bind(this);
		this.handleDisconnected = this.handleDisconnected.bind(this);
		this.handleConnecting = this.handleConnecting.bind(this);
		this.handleDisconnecting = this.handleDisconnecting.bind(this);
		this.handleCCMessage = this.handleCCMessage.bind(this);
		this.handleSendCmd = this.handleSendCmd.bind(this);
		this.handleLoadFile = this.handleLoadFile.bind(this);
		this.handleClearEntries = this.handleClearEntries.bind(this);
	}

	override componentDidMount() {
		this.#cc.on("ready", this.handleReady);
		this.#cc.on("disconnected", this.handleDisconnected);
		this.#cc.on("connecting", this.handleConnecting);
		this.#cc.on("disconnecting", this.handleDisconnecting);

		void this.connect();
	}

	override componentWillUnmount() {
		this.#cc.off("ready", this.handleReady);
		this.#cc.off("disconnected", this.handleDisconnected);
		this.#cc.off("connecting", this.handleConnecting);
		this.#cc.off("disconnecting", this.handleDisconnecting);

		void this.disconnect();
	}

	handleReady() {
		this.setState({
			ready: true,
			connecting: false,
			disconnecting: false,
		});
	}

	handleDisconnected() {
		this.setState({
			ready: false,
			connecting: false,
			disconnecting: false,
		});
	}

	handleConnecting() {
		this.setState({
			connecting: true,
			disconnecting: false,
		});
	}

	handleDisconnecting() {
		this.setState({
			connecting: false,
			disconnecting: true,
		});
	}

	async connect() {
		if (this.#cc.disconnecting) {
			this.#cc.once("disconnected", async () => {
				await this.doConnect();
			});
		} else {
			await this.doConnect();
		}
	}

	async doConnect() {
		if (!this.#cc.connecting) {
			if (this.#cc.gatewayId !== this.props.gatewayId) {
				this.#cc.setLoginPayload({
					tap: this.props.gatewayId,
				});
			}
			this.#cc.on("message-tx", this.handleCCMessage);
			this.#cc.on("message-rx", this.handleCCMessage);
			await this.#cc.connect();
		}
	}

	async disconnect(reconnect = false) {
		if (this.state.ready) {
			if (!this.#cc.disconnecting) {
				if (reconnect) { // Workaround for tab unmount/mount problem
					this.#cc.once("disconnected", async () => {
						await this.doConnect();
					});
				}
				this.#cc.off("message-tx", this.handleCCMessage);
				this.#cc.off("message-rx", this.handleCCMessage);
				await this.#cc.disconnect();
			}
		}
	}

	handleCCMessage(msg: CcMessageTxKnown | CcMessageRxKnown) {
		if ("info" in msg.payload && msg.payload.info === "login" && msg.payload.data) {
			const entry = {
				type: TerminalEntryType.RTRACE, //msg.payload.info,
				data: msg.payload.data,
				ts: new Date().toISOString(),
				dir: Constants.Direction.RX,
			} as const satisfies TerminalEntry;
			this.setState((prevState) => ({
				terminalEntries: [...prevState.terminalEntries, entry],
			}));
		} else if ("action" in msg.payload && (msg.payload.action === "rtrace" || msg.payload.action === "jsonTrace") && msg.payload.data) {
			if ((this.props.module === TerminalType.SHELL && msg.payload.module === TerminalType.SHELL) || (this.props.module === TerminalType.CCC && msg.payload.module !== TerminalType.SHELL)) {
				const entry = {
					type: msg.payload.action,
					data: msg.payload.data,
					ts: msg.payload.ts ?? new Date().toISOString(),
					dir: GatewayTerminal.getDir(msg.payload),
				} as const satisfies TerminalEntry;
				this.setState((prevState) => ({
					terminalEntries: [...prevState.terminalEntries, entry],
				}));
			}
		}
	}

	handleLoadFile(file: File) {
		const fileReader = new FileReader();
		fileReader.readAsText(file, "UTF-8");
		// TODO: replace onload
		fileReader.onload = (event) => { // eslint-disable-line unicorn/prefer-add-event-listener
			try {
				const terminalEntries = JSON.parse(event.target?.result) as TerminalEntries;
				this.setState((prevState) => ({
					terminalEntries: [...prevState.terminalEntries, ...terminalEntries],
				}));
			} catch (error) {
				console.info("Error converting object to string", error);
			}
		};
	}

	static getDir(payload: CcPayloadTxKnown | CcPayloadRxKnown) {
		if (payload.dir) {
			return payload.dir as Direction; // TODO
		}
		if (payload.data?.action) {
			return Constants.Direction.TX;
		}
		if (payload.data?.info) {
			return Constants.Direction.RX;
		}
		return undefined;
	}

	handleSendCmd(command: string) {
		this.setState((prevState) => ({
			terminalEntries: [
				...prevState.terminalEntries,
				{
					type: TerminalEntryType.CMD,
					data: command,
					ts: new Date().toISOString(),
					dir: Constants.Direction.TX,
				} as const satisfies TerminalEntry,
			],
		}));
		const cmd = {
			action: "cc",
			module: this.props.module,
			command: (this.props.module === TerminalType.SHELL) ? `:${command}` : command,
		} as const satisfies CmdCc;
		this.#cc.send(cmd);
	}

	handleClearEntries() {
		this.setState({
			terminalEntries: [],
		});
	}

	override render() {
		const { t, gatewayId, module } = this.props;

		if (this.state.disconnecting) {
			return <CircularProgress />;
		}
		if (!this.state.connecting && !this.state.ready) {
			return <span>{t("generic.disconnected")}</span>;
		}

		return (
			<Paper>
				<Terminal
					cc={this.#cc}
					module={module}
					readOnly={!User.hasLevel("ccc_write")}
					gatewayId={gatewayId}
					terminalEntries={this.state.terminalEntries}
					onLoadFile={this.handleLoadFile}
					onSendCmd={this.handleSendCmd}
					onClear={this.handleClearEntries}
				/>
			</Paper>
		);
	}

}

export default withTranslation()(GatewayTerminal);
