import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
// cmp
import RowEntry from "../row-entry";
// types
import type { GridColDef } from "@mui/x-data-grid";

interface Running {
	name: string;
	version: string;
	last_update?: string;
}
interface Config {
	name: string;
	version?: string;
}

type Props = Readonly<{
	firmware: {
		running: Array<Running>;
		config: Array<Config>;
		group: string;
	};
}>;

const UpdateFirmwareInfo = ({ firmware }: Props) => {
	const { t } = useTranslation();

	const getPreviousVersion = (name: string) => {
		const config = firmware.config.find((item) => (item.name === name));
		return config?.version ?? "";
	};

	const isCurrentVersionAvailable = firmware.running?.length > 0;

	const columns = useMemo(() => ([
		{
			field: "plugin",
			headerName: t("gateway.plugin"),
			flex: 1,
		},
		{
			field: "lastUpdated",
			headerName: t("gateway.lastUpdated"),
			flex: 1,
		},
		{
			field: "currentVersion",
			headerName: t("gateway.currentVersion"),
			flex: 3,
		},
		{
			field: "configuredVersion",
			headerName: t("gateway.configuredVersion"),
			flex: 1,
		},
	] as const satisfies ReadonlyArray<GridColDef>), [t]);

	const runningRowMap = (running: Running) => ({
		id: running.name,
		plugin: running.name,
		lastUpdated: new Date(running.last_update ?? null).toLocaleString() ?? t("messages.na"),
		currentVersion: running.version ?? t("messages.na"),
		configuredVersion: getPreviousVersion(running.name) ?? t("messages.na"),
	});

	const configRowMap = (config: Config) => ({
		id: config.name,
		plugin: config.name,
		lastUpdated: t("messages.na"),
		currentVersion: t("messages.na"),
		configuredVersion: config.version ?? t("messages.na"),
	});

	return (
		<>
			{firmware.group &&
				<Paper style={{ margin: "16px" }} elevation={2}>
					<RowEntry title={t("gateway.gatewayGroup")}>
						{firmware.group}
					</RowEntry>
				</Paper>
			}
			<DataGrid
				columns={columns}
				rows={isCurrentVersionAvailable ? firmware.running.map(runningRowMap) : firmware.config.map(configRowMap)}
			/>
		</>
	);
};

export default UpdateFirmwareInfo;
